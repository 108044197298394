// extracted by mini-css-extract-plugin
export var close = "image-gallery-module--close--e673d";
export var galleryContainer = "image-gallery-module--gallery-container--5ab0e";
export var imageButton = "image-gallery-module--image-button--08305";
export var itemButton = "image-gallery-module--item-button--ec36e";
export var miniature = "image-gallery-module--miniature--394fc";
export var modal = "image-gallery-module--modal--b6fe6";
export var modalBaseImage = "image-gallery-module--modal-base-image--c481a";
export var modalRatioBox = "image-gallery-module--modal-ratio-box--98c41";
export var modalSlider = "image-gallery-module--modal-slider--7345f";
export var open = "image-gallery-module--open--7c814";