import React from 'react';
import { Image } from '@alterpage/gatsby-plugin-image';

import {
    grid,
    container,
    description as descriptionClass,
    markdown,
    category,
    title,
    descriptionTitle,
    backButton,
    bottomWrapper,
} from './product-simple.module.scss';

import { ISection } from '../../models/section.model';
import { IProduct } from '../../models/product.model';
import { relations } from '../../config/relations';
import { IMedia } from '@alterpage/gatsby-source-alterpress';

import Section from '../hoc/section';
import Markdown from '../hoc/markdown';
import Button from '../atoms/button';
import useTranslations from '../../hooks/use-translations';
import ImageGallery from '../molecules/image-gallery';

export interface IProductSection extends ISection {
    extendedItems: {
        product: IProduct;
    };
}

export interface IProductProps {
    className?: string;
    section: IProductSection;
    TitleTag?: React.ElementType;
}

export default function ProductSimple({ className = '', section }: IProductProps) {
    const { sectionId, css, style } = section;
    const t = useTranslations('ProductSimple');
    const { product } = section.extendedItems;
    const { description } = product;
    const schematicImage = getSchematicImage(product);
    const galleryImages = getGalleryImages(product);

    return (
        <Section
            sectionId={sectionId}
            className={[grid, className].join(' ')}
            css={css}
            style={style}
            classes={{ container }}
        >
            {product.categories && (
                <Button as={'link'} to={product.categories[0].pathname} className={category}>
                    {product.categories[0].name}
                </Button>
            )}
            <h1 className={title}>{product.name}</h1>
            <div className={descriptionClass}>
                <Image media={schematicImage} relation={relations.galleryImage} />
                <div className={markdown}>
                    <p className={descriptionTitle}>{t.description}</p>
                    <Markdown>{description}</Markdown>
                </div>
            </div>
            <ImageGallery media={galleryImages} />
            {product.categories && (
                <div className={bottomWrapper}>
                    <Button
                        as={'link'}
                        to={product.categories[0].pathname}
                        className={backButton}
                        context={'withArrow'}
                    >
                        {t.back}
                    </Button>
                </div>
            )}
        </Section>
    );
}

function getSchematicImage(product: IProduct) {
    const image: IMedia[] = [];

    product.media.forEach((item) => {
        item.relations.forEach((relation) => {
            if (relation.relation === relations.galleryImage && relation.sequence === 0) {
                image.push(item);
            }
        });
    });

    return image;
}

function getGalleryImages(product: IProduct) {
    const images: IMedia[] = [];

    product.media.forEach((item) => {
        item.relations.forEach((relation) => {
            if (relation.relation === relations.galleryImage && relation.sequence !== 0) {
                images.push(item);
            }
        });
    });

    return images;
}
