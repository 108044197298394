import React, { useEffect, useState } from 'react';
import Swiper, { SwiperOptions, Thumbs, Keyboard, Zoom } from 'swiper';
import { getImage } from 'gatsby-plugin-image';
import { IMedia } from '@alterpage/gatsby-source-alterpress';
import { BaseImage, Image } from '@alterpage/gatsby-plugin-image';

import {
    modal,
    modalSlider,
    close,
    open,
    modalBaseImage,
    modalRatioBox,
    galleryContainer,
    imageButton,
} from './image-gallery.module.scss';
import CloseIcon from '../../assets/images/svg/close.svg';

import Slider from '../hoc/slider';
import { relations } from '../../config/relations';

interface IImageGalleryProps {
    className?: string;
    media: IMedia[];
    itemRelation?: number;
}

const ImageGallery: React.FC<IImageGalleryProps> = ({ className = '', media }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalSwiper, setModalSwiper] = useState<Swiper | null>(null);

    const handleToggleModal = (i: number | undefined = undefined): void => {
        setIsModalOpen((prevIsModalOpen) => {
            const newIsModalOpen = !prevIsModalOpen;

            if (newIsModalOpen && i !== undefined) {
                // little hack: when swiper with setting loop: true goes to the slide,
                // unfortunately it goes to the slide before the one we wanted to set,
                // so we add 1 to make it go to the slide we want

                modalSwiper?.slideTo(i + 1, 0);
            }
            return newIsModalOpen;
        });
    };

    useEffect(() => {
        const handleEscClick = (e: KeyboardEvent) => {
            if (isModalOpen && e.code === 'Escape') {
                handleToggleModal();
            }
        };
        window.addEventListener('keydown', handleEscClick);
        return () => window.removeEventListener('keydown', handleEscClick);
    }, [isModalOpen]);

    return (
        <div className={`${className}`}>
            <div className={galleryContainer}>
                {media.map((image, i) => {
                    return (
                        <button
                            className={imageButton}
                            key={`gallery-image-${i}`}
                            onClick={() => {
                                handleToggleModal(Number(i));
                            }}
                        >
                            <Image media={[image]} relation={relations.galleryImage} />
                        </button>
                    );
                })}
            </div>
            <div className={`${modal} ${isModalOpen ? open : ''}`}>
                <button
                    className={close}
                    onClick={() => {
                        handleToggleModal();
                    }}
                >
                    <CloseIcon />
                </button>
                {media.length > 0 && (
                    <Slider
                        className={modalSlider}
                        {...modalSliderSettings}
                        onSwiper={setModalSwiper}
                    >
                        {media.map((item) => (
                            <div
                                className="swiper-zoom-container"
                                key={`item-zoom-container-${item.fileId}`}
                            >
                                <BaseImage
                                    className={`swiper-zoom-target ${modalBaseImage}`}
                                    ratioClass={modalRatioBox}
                                    {...getCommonImageProps(item)}
                                    objectFit="scale-down"
                                />
                            </div>
                        ))}
                    </Slider>
                )}
            </div>
        </div>
    );
};

function getCommonImageProps(item: IMedia) {
    return {
        image: item.localFile && getImage(item.localFile),
        objectFit: 'contain',
        alt: item.alt,
        url: item.url,
    } as const;
}

const commonSliderSettings: SwiperOptions = {
    modules: [Thumbs],
};

const modalSliderSettings: SwiperOptions = {
    ...commonSliderSettings,
    speed: 500,
    slidesPerView: 1,
    loop: true,
    navigation: true,
    spaceBetween: 20,
    keyboard: {
        enabled: true,
    },
    zoom: true,
    modules: [Keyboard, Zoom],
};

export default ImageGallery;
