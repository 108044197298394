import React, { useContext, useState } from 'react';
import { IProductVariant } from '../models/product-variant.model';
import { IProductOptionValue } from '../models/product.model';

export interface IProductContext {
    activeVariant: IProductVariant | undefined;
    setActiveVariant: React.Dispatch<React.SetStateAction<IProductVariant | undefined>>;
    activeOptions: (IProductOptionValue | undefined)[] | undefined;
    setActiveOptions: React.Dispatch<
        React.SetStateAction<(IProductOptionValue | undefined)[] | undefined>
    >;
}

const defaultContextValue: IProductContext = {
    activeVariant: undefined,
    setActiveVariant: () => {},
    activeOptions: undefined,
    setActiveOptions: () => {},
};

const ProductContext = React.createContext(defaultContextValue);

export const ProductContextProvider: React.FC<{
    children: React.ReactNode;
    options: (IProductOptionValue | undefined)[] | undefined;
    variant: IProductVariant | undefined;
}> = ({ children, options, variant }) => {
    const [activeVariant, setActiveVariant] = useState<IProductVariant | undefined>(variant);
    const [activeOptions, setActiveOptions] = useState<
        (IProductOptionValue | undefined)[] | undefined
    >(options);

    return (
        <ProductContext.Provider
            value={{ activeVariant, setActiveVariant, activeOptions, setActiveOptions }}
        >
            {children}
        </ProductContext.Provider>
    );
};

export const useProductContext = () => {
    const context = useContext(ProductContext);

    if (context === undefined) {
        throw new Error('useProductContext was used outside of its Provider');
    }

    return context;
};
